export function auth(login, password) {
  return fetch('/api/auth.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({login, password})
  })
  .then(response => response.json())
  .then(response => {
    if(response.errors !== 0) {
      throw new Error(response.data);
    }
    localStorage.setItem("hash", response.data);
    return true;
  })
  .catch(err => {
    console.error(err);
    return err.message;
  })
}

export function checkAuth() {
  return fetch('/api/checkAuth.php', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      "Authentication": localStorage.hash
    }
  })
  .then(response => response.json())
  .then(response => {
    if(response === false) {
      throw new Error("Хэш не соответствует");
    }
    return true;
  })
  .catch(err => {
    console.error(err);
    localStorage.removeItem('hash')
    return false;
  })
} 

export function logout() {
  return fetch('/api/logout.php', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      "Authentication": localStorage.hash
    }
  })
  .then(response => response.json())
  .then(response => {
    if(response === false) {
      throw new Error("Хэш не соответствует");
    }
    localStorage.removeItem('hash');
    return true;
  })
  .catch(err => {
    console.error(err);
    localStorage.removeItem('hash');
    return false;
  })
}

export function isAdmin() {
  return fetch('/api/isAdmin.php', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      "Authentication": localStorage.hash
    }
  })
  .then(response => response.json())
}