export default class LeadService {
  getLeads() {
    return fetch(`/api/getLeads.php`, {
      method: "GET",
      headers: {
        "Authentication": localStorage.hash
      }
    })
      .then((response) => response.json())
      .then((d) => d.leads);
  }

  removeLead(element) {
    return fetch('/api/removeLead.php', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        "Authentication": localStorage.hash
      },
      body: JSON.stringify(element)
    })
    .then(response => response.json())
    .then(response => {
      if(response.errors !== 0) {
        throw new Error(response.data);
      }
      return response.data;
    })
    .catch(err => {
      console.error(err);
      return err.message;
    })
  }

  editLead(element) {
    return fetch('/api/changeLead.php', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        "Authentication": localStorage.hash
      },
      body: JSON.stringify(element)
    })
    .then(response => response.json())
    .then(response => {
      if(response.errors !== 0) {
        throw new Error(response.data);
      }
      return true;
    })
    .catch(err => {
      console.error(err);
      return err.message;
    })
  }

  createLead(element) {
    element.sum = element.sum ? element.sum.replaceAll(',','.') : element.sum;
    return fetch('/api/createLead.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        "Authentication": localStorage.hash
      },
      body: JSON.stringify(element)
    })
    .then(response => response.json())
    .then(response => {
      if(response.errors !== 0) {
        throw new Error(response.data);
      }
      return response.data;
    })
    .catch(err => {
      console.error(err);
      return err.message;
    })
  }

  exportLeads() {
    return fetch('/api/exportTable.php', {
      method: 'GET',
      headers: {
        "Authentication": localStorage.hash
      }
    })
    .then(response => response.json())
    .then(response => {
      if(response.errors !== 0) {
        throw new Error(response.data);
      }
      const a = document.createElement("a");
      a.href = response.file;
      document.body.append(a);
      a.download = "export_table.xls";
      a.click();
      a.remove();
      return true
    })
    .catch(err => {
      console.error(err);
      return err.message;
    })
  }
}
