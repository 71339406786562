<template>
  <form class="auth" @submit.prevent="auth">
    <div class="auth_wrapper">
      <h1 class="auth_h1">Авторизация</h1>
      <span class="p-float-label auth_input">
        <InputText id="username" type="text" v-model="username" />
        <label for="username">Логин</label>
      </span>
      <span class="p-float-label auth_input">
        <InputText id="password" type="password" v-model="password" />
        <label for="password">Пароль</label>
      </span>
      <Button label="Войти" class="auth_btn" type="submit"/>
    </div>
  </form>
</template>

<script>
import { auth } from "../API/auth";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    auth() {
      if(!this.username || !this.password) {
        alert("Введите логин и пароль");
        return;
      }
      auth(this.username, this.password)
      .then(result => {
        if(result === true) {
          this.$emit('auth');
        } else {
          this.$toast.add({severity:'error', summary: result, life: 3000});
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.auth {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_wrapper {
    padding: 16px 32px;
    box-shadow: 0 0 15px rgba(0,0,0,.5);
    border: 1px solid black;
  }

  &_h1 {
    margin-bottom: 64px;
  }
  &_input {
    margin-bottom: 32px;
  }
  &_btn {
    display: block;
    margin: 0 auto;
  }
}
</style>