<template>
  <div>
    <DataTable
      :value="filteredleads"
      :paginator="true"
      class="p-datatable-leads"
      :rows="5"
      ref="table"
      dataKey="id"
      :rowHover="true"
      v-model:filters="filters"
      filterDisplay="menu"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25, 50, 100]"
      currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
      :globalFilterFields="['client', 'auto', 'id']"
      responsiveLayout="stack"
      breakpoint="1200px"
      @keydown.enter="acceptEdit(leads.find((lead) => lead.isEdit === true))"
    >
      <template #header>
        <div>
          <h5 class="m-0">Заявки</h5>
          <span class="p-input-icon-left" style = "width: 100%; max-width: 500px;">
            <i class="pi pi-search search_icon" />
            <InputText
              style = "width: 100%;"
              v-model="filters['global'].value"
              placeholder="Введите ID, ФИО или модель машины для поиска"
            />
          </span>
          <div class="table_filter">
            <div class="table_filter_date">
              <h6 class="table_filter_date_title">Фильтрация по дате</h6>
              <Calendar
                v-model="dateFilterFrom.value"
                placeholder="От"
                dateFormat="dd/mm/yy"
              ></Calendar>
              <Calendar
                v-model="dateFilterTo.value"
                placeholder="До"
                dateFormat="dd/mm/yy"
              ></Calendar>
            </div>
            <Button
              icon="pi pi-plus"
              class="p-button-outlined"
              v-if="isAdmin"
              label="Добавить заявку"
              style="margin-left: 20px; display: block"
              @click="createLead"
            />
            <Button
              icon="pi pi-file-excel"
              class="p-button-outlined p-button-help"
              label="Экспорт в XSL"
              style="margin-left: 20px; margin-right: 20px; display: block"
              @click="exportTable"
            />
            <div class="table_filter_status" v-if="isAdmin">
              <span class="table_filter_status_title">Статус: </span>
              <Dropdown
                v-model="statusFilter.value"
                :options="[...statuses, { value: 'Любой', class: 'any' }]"
                optionValue="value"
              >
                <template #value="slotProps">
                  <span :class="'lead-badge status-' + slotProps.value">{{
                    slotProps.value
                  }}</span>
                </template>
                <template #option="slotProps">
                  <span
                    :class="'lead-badge status-' + slotProps.option.value"
                    >{{ slotProps.option.value }}</span
                  >
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
      </template>
      <template #empty> Заявки не найдены. </template>
      <template #loading> Загрузка, пожалуйста подождите </template>
      <Column field="id" header="ID" sortable>
        <template #body="{ data }">
          {{ data.id }}
        </template>
      </Column>
      <Column field="createdAt" header="Дата создания" dataType="date" sortable>
        <template #body="{ data }">
          <span>{{ formatDate(data.createdAt) }}</span>
        </template>
      </Column>
      <Column field="client" header="ФИО" sortable>
        <template #body="{ data }">
          <span v-if="!data.isEdit">{{ data.client }}</span>
          <InputText v-else type="text" v-model="data.client" name="client" />
        </template>
      </Column>
      <Column field="source" header="Источник" sortable>
        <template #body="{ data }">
          <span v-if="!data.isEdit">{{ data.source }}</span>
          <Dropdown
            v-else
            v-model="data.source"
            :options="sources"
            :placeholder="data.source"
          >
            <template #value="slotProps">
              <span :class="'lead-badge status-' + slotProps.value">{{
                slotProps.value
              }}</span>
            </template>
            <template #option="slotProps">
              <span :class="'lead-badge status-' + slotProps.option">{{
                slotProps.option
              }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="status" header="Статус" sortable>
        <template #body="{ data }">
          <Dropdown
            v-if="!data.isEdit && isAdmin"
            v-model="data.status"
            :options="statuses"
            :placeholder="data.status"
            optionValue="value"
            @change="acceptEdit(data, true)"
          >
            <template #value="slotProps">
              <span :class="'lead-badge status-' + slotProps.value">{{
                slotProps.value
              }}</span>
            </template>
            <template #option="slotProps">
              <span :class="'lead-badge status-' + slotProps.option.class">{{
                slotProps.option.value
              }}</span>
            </template>
          </Dropdown>
          <span v-else-if="!isAdmin">{{ data.status }}</span>
          <Dropdown
            v-else
            v-model="data.status"
            :options="statuses"
            :placeholder="data.status"
            optionValue="value"
          >
            <template #value="slotProps">
              <span :class="'lead-badge status-' + slotProps.value">{{
                slotProps.value
              }}</span>
            </template>
            <template #option="slotProps">
              <span :class="'lead-badge status-' + slotProps.option.class">{{
                slotProps.option.value
              }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="auto" header="Авто" sortable>
        <template #body="{ data }">
          <span v-if="!data.isEdit"
            >{{ data.auto }} - {{ data.autoNumber }}</span
          >
          <div v-else>
            <InputText type="text" v-model="data.auto" />
            <InputText type="text" v-model="data.autoNumber" />
          </div>
        </template>
      </Column>
      <Column field="dateFrom" header="Дата аренды" dataType="date" sortable v-if="isAdmin">
        <template #body="{ data }">
          <span v-if="!data.isEdit"
            >{{ formatDate(data.dateFrom) }} -
            {{ formatDate(data.dateTo) }}</span
          >
          <div v-else>
            <Calendar
              dateFormat="dd/mm/yy"
              :placeholder="data.dateFrom"
              v-model="data.dateFrom"
              :showTime="true"
              name="dateFrom"
            />
            <Calendar
              dateFormat="dd/mm/yy"
              :placeholder="data.dateTo"
              v-model="data.dateTo"
              :showTime="true"
              name="dateTo"
            />
          </div>
        </template>
      </Column>
      <Column field="placeFrom" header="Место получения - сдачи" sortable> 
        <template #body="{ data }">
          <span v-if="!data.isEdit"
            >{{ data.placeFrom }} - {{ data.placeTo }}</span
          >
          <div v-else>
            <Dropdown
              v-model="data.placeFrom"
              :options="places"
              :placeholder="data.placeFrom"
            >
              <template #value="slotProps">
                <span :class="'lead-badge status-' + slotProps.value">{{
                  slotProps.value
                }}</span>
              </template>
              <template #option="slotProps">
                <span :class="'lead-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
            <Dropdown
              v-model="data.placeTo"
              :options="places"
              :placeholder="data.placeTo"
            >
              <template #value="slotProps">
                <span :class="'lead-badge status-' + slotProps.value">{{
                  slotProps.value
                }}</span>
              </template>
              <template #option="slotProps">
                <span :class="'lead-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </div>
        </template>
      </Column>
      <Column field="sum" header="Сумма сделки" sortable v-if="isAdmin">
        <template #body="{ data }">
          <span v-if="!data.isEdit">{{ formatCurrency(data.sum) }}</span>
          <InputText v-else type="text" v-model="data.sum" />
        </template>
      </Column>
      <Column field="sum" header="Сумма сделки | Процент сделки" sortable v-else>
        <template #body="{ data }">
          <span v-if="!data.isEdit">{{ formatCurrency(data.sum) }} | {{ formatCurrency(data.sum * 0.1) }}</span>
          <InputText v-else type="text" v-model="data.sum" />
        </template>
      </Column>
      <Column field="comment" header="Комментарий" sortable>
        <template #body="{ data }">
          <span v-if="!data.isEdit">{{ data.comment }}</span>
          <InputText v-else type="text" v-model="data.comment" name="comment" />
        </template>
      </Column>
      <Column v-if="isAdmin">
        <template #body="{ data }">
          <div v-if="!data.isEdit" class = "buttons_container">
            <Button
              type="button"
              icon="pi pi-pencil"
              style="margin-bottom: 8px; display: block"
              @click="toggleEdit(data)"
            ></Button>
            <Button
              class="p-button-danger"
              type="button"
              icon="pi pi-trash"
              @click="removeLead(data)"
            ></Button>
          </div>
          <div v-else class = "buttons_container">
            <Button
              class="p-button-success"
              type="button"
              icon="pi pi-check"
              style="margin-bottom: 8px; display: block"
              @click="acceptEdit(data)"
            ></Button>
            <Button
              class="p-button-danger"
              type="button"
              icon="pi pi-times"
              @click="cancelEdit(data)"
            ></Button>
          </div>
        </template>
      </Column>
    </DataTable>
    <Button
      icon="pi pi-plus"
      class="p-button-outlined"
      v-if="isAdmin"
      label="Добавить заявку"
      style="margin: 60px auto; display: block"
      @click="createLead"
    />
  </div>
</template>

<script>
import leadService from "../API/LeadService";
import { FilterMatchMode } from "primevue/api";

export default {
  props: {
    isAdmin: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      leads: null,
      dateFrom: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      dateFilterFrom: { value: null },
      dateFilterTo: { value: null },
      statusFilter: { value: "Любой" },
      loading: true,
      statuses: [
        { value: "Успешно", class: "ok" },
        { value: "Не успешно", class: "not-ok" },
        { value: "В процессе", class: "pending" },
      ],
      sources: ["Телефон", "Сайт РКК", "Сайт аэропорта"],
      places: [
        "Офис компании РКК",
        "Аэропорт",
        "Доставка по адресу",
        "Ж/Д Вокзал",
      ],
      editedElement: null,
    };
  },
  created() {
    this.leadService = new leadService();
  },
  mounted() {
    this.leadService.getLeads().then((data) => {
      this.leads = data;
      this.leads.forEach((lead) => {
        lead.dateFrom = new Date(lead.dateFrom);
        lead.dateTo = new Date(lead.dateTo);
        lead.createdAt = new Date(lead.createdAt);
      });
      this.loading = false;
    });

    const UPDATE_PERIOD = 1000 * 10;

    setInterval(() => {
      this.leadService.getLeads().then((data) => {
        if (!data.length) {
          return;
        }

        const actualIds = this.leads.map((item) => item.id);
        // Обновим старые
        actualIds.map((id) => {
          const updatedLead = data.find((lead) => lead.id == id);
          if (updatedLead) {
            updatedLead.dateFrom = new Date(updatedLead.dateFrom);
            updatedLead.dateTo = new Date(updatedLead.dateTo);
            updatedLead.createdAt = new Date(updatedLead.createdAt);

            const oldLead = this.leads.find((lead) => lead.id == id);

            if (oldLead.isEdit) {
              return;
            }

            for (let prop in updatedLead) {
              oldLead[prop] = updatedLead[prop];
            }
          }
        });

        const differenceLeads = data.filter(
          (lead) => !actualIds.includes(lead.id)
        );

        // Добавим лиды, которых еще нет
        differenceLeads.map((lead) => {
          lead.dateFrom = new Date(lead.dateFrom);
          lead.dateTo = new Date(lead.dateTo);
          lead.createdAt = new Date(lead.createdAt);

          this.leads.unshift(lead);
        });
      });
    }, UPDATE_PERIOD);

    // Check admin permissions
    this.$emit("check-admin");
  },
  methods: {
    formatDate(value) {
      if (!value) {
        return;
      }
      return value.toLocaleTimeString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    formatCurrency(value) {
      if (!value) {
        return;
      }
      value = +value;
      return value.toLocaleString(undefined, {
        style: "currency",
        currency: "RUB",
      });
    },
    createLead() {
      const lead = this.leads.find((lead) => lead.isEdit === true);
      if (lead) {
        this.acceptEdit(lead);
      }

      this.leads.unshift({
        createdAt: new Date(),
        isEdit: true,
        source: "Телефон",
        status: "В процессе",
        placeFrom: "Офис компании РКК",
        placeTo: "Офис компании РКК",
      });
    },
    toggleEdit(element) {
      if (element.isEdit) {
        delete element.isEdit;
        this.editedElement = null;
      } else {
        this.editedElement = { ...element };
        element.isEdit = true;
      }
    },
    acceptEdit(element, isStatus = false) {
      if (!element) {
        return;
      }

      if (!element.id) {
        this.leadService.createLead(element).then((result) => {
          if (result >= 0) {
            element.id = result;
            this.toggleEdit(element);
            this.$toast.add({
              severity: "success",
              summary: `Заявка с ID: ${element.id} создана`,
              life: 3000,
            });
          } else {
            this.$toast.add({ severity: "error", summary: result, life: 3000 });
          }
        });
        return;
      }

      this.leadService.editLead(element).then((result) => {
        if (result === true && isStatus === false) {
          this.toggleEdit(element);
          this.$toast.add({
            severity: "success",
            summary: `Заявка с ID: ${element.id} изменена`,
            life: 3000,
          });
        } else if (result !== true) {
          this.$toast.add({ severity: "error", summary: result, life: 3000 });
        }
      });
    },
    removeLead(element) {
      if (
        !confirm(`Вы уверены что хотите удалить элемент с ID: ${element.id}?`)
      ) {
        return;
      }

      this.leadService.removeLead(element).then((leadId) => {
        if (!leadId) {
          this.$toast.add({
            severity: "error",
            summary: `При удалении заявки с ID: ${element.id} возникла ошибка`,
            life: 3000,
          });
          return;
        }
        if (leadId >= 0) {
          this.leads = this.leads.filter((lead) => lead.id != leadId);
          this.$toast.add({
            severity: "success",
            summary: `Заявка с ID: ${leadId} удалена`,
            life: 3000,
          });
        }
      });
    },
    cancelEdit(element) {
      if (!element.id) {
        this.leads.shift();
      }

      for (let prop in this.editedElement) {
        element[prop] = this.editedElement[prop];
      }
      this.toggleEdit(element);
    },
    exportTable() {
      this.leadService.exportLeads().then((result) => {
        if (result !== true) {
          this.$toast.add({ severity: "error", summary: result, life: 3000 });
        }
      });
    },
  },
  computed: {
    filteredleads() {
      if (
        !this.leads &&
        (this.dateFilterFrom.value ||
          this.dateFilterTo.value ||
          this.statusFilter.value)
      ) {
        return;
      }

      let filteredleads = this.leads;
      if (this.dateFilterFrom.value) {
        filteredleads = filteredleads.filter((item) => {
          return item.createdAt >= this.dateFilterFrom.value;
        });
      }
      if (this.dateFilterTo.value) {
        filteredleads = filteredleads.filter((item) => {
          return item.createdAt <= this.dateFilterTo.value;
        });
      }
      if (this.statusFilter.value && this.statusFilter.value !== "Любой") {
        filteredleads = filteredleads.filter((item) => {
          return item.status === this.statusFilter.value;
        });
      }

      return filteredleads;
    },
  },
};
</script>

<style lang="scss" scoped>
.table_filter {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  &_status {
    display: flex;
    align-items: center;
    margin-left: auto;
    &_title {
      margin-right: 16px;
      font-size: 14px;
    }
  }
  &_date {
    display: block;
    &_title {
      margin-top: 20px;
      margin-bottom: 8px;
      display: block;
    }
  }
}
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-leads) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }
}
@media screen and (max-width: 1400px) {
  ::v-deep(.p-datatable.p-datatable-leads) {
    .p-datatable-tbody > tr > td,
    .p-datatable-thead > tr > th {
      padding: 0.2rem 0.2rem;
    }
    .p-inputtext {
      padding: 0.2rem 0.2rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .search_icon {
    display: none;
  }
  .table_filter {

    &_date {
      margin-bottom: 20px;
      margin-right: 20px;
    }

    .p-button {
      margin-left: 0 !important;
      margin-right: 20px !important;
      margin-bottom: 20px;
    }
  }
  ::v-deep(.p-datatable) {
    table {
      min-width: unset;
      max-width: 600px;
      width: 100%;
      margin: 40px auto;
    }
  }
  ::v-deep(.p-datatable.p-datatable-leads) {
    .p-datatable-tbody > tr {
      border-bottom: 40px solid transparent;
    }
    .p-datatable-tbody > tr > td {
      border-bottom: 1px solid #ccc !important;
      padding: 1rem 1rem;
    }
    .buttons_container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      button {
        margin: 0 !important;
      }
    }
  }
}
</style>