import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import PrimeVue from 'primevue/config';
import { makeServer } from "./fakeServer"

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons

import Menubar from 'primevue/menubar';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

const app = createApp(App);


if (process.env.NODE_ENV === "development") {
  makeServer()
}

app.use(PrimeVue);
app.component('Menubar', Menubar);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('InputText', InputText);
app.component('Calendar', Calendar);
app.component('Button', Button);
app.component('Dropdown', Dropdown);
app.component('Toast', Toast);

app.use(ToastService);

app.use(store).mount('#app');
