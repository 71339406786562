<template>
  <Toast />
  <div v-if="isAuth === true">
    <the-header @logout = "logout"></the-header>
    <the-table @check-admin = "checkAdmin" :is-admin="isAdmin"></the-table>
  </div>
  <auth-form v-else-if="isAuth === false" @auth="auth()"/>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheTable from "./components/TheTable.vue";
import AuthForm from "./components/AuthForm.vue";

import { checkAuth, logout, isAdmin } from "./API/auth";

export default {
  components: { TheHeader, TheTable, AuthForm },
  beforeCreate() {
    checkAuth()
    .then(result => {
      if(result === true) {
        this.auth();
      } else {
        this.logout();
      }
    });
  },
  data() {
    return { 
      isAuth: null,
      isAdmin: false,
    }
  },
  methods: {
    auth() {
      this.isAuth = true;
    },
    logout() {
      logout();
      this.isAuth = false;
    },
    checkAdmin() {
      isAdmin()
      .then(result => {
        if(result) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      });
    }
  },
};
</script>

<style lang="scss">
</style>
