<template>
  <div>
    <Menubar :model="items">
      <template #start>
        <img
          alt="logo"
          src="/img/logo.svg"
          class="p-menubar-logo"
          height="40"
        />
      </template>
    </Menubar>
  </div>
</template>

		<script>
export default {
  props: ['export'],
  data() {
    return {
      items: [
        /*
        {
          label: "Файл",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Добавить",
              icon: "pi pi-fw pi-plus",
            },
            {
              separator: true,
            },
            {
              label: "Экспорт",
              icon: "pi pi-fw pi-external-link",
              command: () => {
                //this.$emit('export');
              }
            },
          ],
        },
                */
        {
          label: "Выход",
          style: "margin-left: auto;",
          icon: "pi pi-fw pi-power-off",
          command: () => {
            this.$emit('logout');
          }
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.p-menubar-root-list {
  width: 100%;
}

.p-menubar-logo {
  margin-right: 20px;
}
</style>